import { useEffect, useId, useRef, useState } from 'react';
import { lowerCase, capitalize, isString } from 'lodash';
import Quill, { TextChangeHandler, SelectionChangeHandler } from 'quill';
import QuillBetterTablePlus from 'quill-better-table-plus';
import { angularize } from 'react-in-angularjs';
import ValidLabel from '@/src/components/label/ValidLabel.component';
import LanguageFlag from '@/src/components/languageFlag/LanguageFlag.component';
import NotificationPlaceholders from '@/src/components/quill/NotificationPlaceholders.component';
import { applyQuillFormat, getQuillConfig, isQuillEmpty } from '@/src/services/quill.service';
import type { QuillTextEditorProps } from '@/src/types/quill.types';

// Register beter-table-plus
Quill.register({
  'modules/better-table-plus': QuillBetterTablePlus
}, true);

// Quill uses <strong> by default
const bold = Quill.import('formats/bold');
bold.tagName = 'b';
Quill.register(bold, true);

// Quill uses <em> by default
const italic = Quill.import('formats/italic');
italic.tagName = 'i';
Quill.register(italic, true);

const QuillTextEditor =
  ({
    $scope,
    value,
    insertTable,
    stripHtml,
    disabled,
    notificationTags,
    languageData,
    requiredData,
    formats,
    readOnly,
    placeholder,
    toolbar
  }: Readonly<QuillTextEditorProps>) => {
    const id = useId();

    // predefine refs
    const quillRef = useRef<Quill | null>(null);
    const quillToolbarRef = useRef<HTMLDivElement | null>(null);
    const quillEditorRef = useRef<HTMLDivElement | null>(null);

    // get Angular controller from parent $scope
    const $angularController = ($scope.$parent as any).htmlTextQuillController;

    // update inner html to correct allow spell check
    const setSpellCheck = () => {
      if (!quillEditorRef.current) return;
      const language = lowerCase(languageData.language);

      const paragraphs = quillEditorRef.current.querySelectorAll('.ql-editor p') as NodeListOf<HTMLParagraphElement>;
      paragraphs.forEach((paragraph) => {
        if (disabled) paragraph.removeAttribute('contenteditable');
        else paragraph.setAttribute('contenteditable', '');

        paragraph.setAttribute('spellcheck', 'true');
        paragraph.setAttribute('lang', language);
      });
    };

    // Selection change event
    const onQuillSelectionChange: SelectionChangeHandler = (range) => {
      if (range) {
        return;
      }

      $scope.$parent.$apply(function () {
        $angularController.ngModelCtrl.$setTouched();
      });
    };

    // Text change event
    const [textValue, setTextValue] = useState<string>(value);
    const onQuillTextChange: TextChangeHandler = () => {
      const { current: editorElem } = quillEditorRef;
      const { current: quill } = quillRef;

      if (!editorElem) return;
      if (!quill) return;


      setSpellCheck();
      const { innerHTML } = (editorElem.querySelector('.ql-editor') as HTMLDivElement);
      const text = quill.getText();

      let html = applyQuillFormat(innerHTML, toolbar?.align);
      if (html === '<br/>') html = '';

      $scope.$parent.$apply(() => {
        // remove last character (/n) added by Quill from the plain text
        const plainText = text.split('');
        plainText.pop();
        $angularController.plainText = plainText.join('');

        // set view value
        $angularController.ngModelCtrl.$setViewValue(html);
      });

      setTextValue(text);
    };

    useEffect(() => {
      const { current: quillToolbar } = quillToolbarRef;
      const { current: quillEditor } = quillEditorRef;
      if (!quillToolbar || !quillEditor) return;

      const quill = new Quill(quillEditor, getQuillConfig({
        formats,
        readOnly,
        placeholder,
        modules: {
          toolbar: quillToolbar
        }
      }));

      quillRef.current = quill;

      // set event listener for text change
      quill.on('text-change', onQuillTextChange);
      quill.on('selection-change', onQuillSelectionChange);

      if (value) quill.setText(value, 'user');

      if (stripHtml) {
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (_node, delta) => {
          delta.ops = delta.ops.map((opsValue: any) => {
            const { insert, attributes } = opsValue;
            if (!attributes) return opsValue;

            // table paste is handled as HTML. So we make an exception for pasting tables
            const tableAttribute = Object.keys(attributes).some((key) => key.includes('table'));
            if (tableAttribute && insertTable) return opsValue;

            if (insert && isString(insert)) return { insert };
          });

          return delta;
        });
      }
      setSpellCheck();

      return () => {
        quill.off('text-change', onQuillTextChange);
        quill.off('selection-change', onQuillSelectionChange);
      };
    }, []);

    const insertNewTable = () => {
      const { current: quill } = quillRef;
      if (!quill) return;

      const tableModule = quill.getModule('better-table-plus') as any;
      tableModule.insertTable(3, 3);
    };

    return (
      <div>
        <div ref={quillToolbarRef} className='d-f justify-content-between align-items-center'>
          <div className='d-f align-items-center'>
            <LanguageFlag language={languageData.language} tooltip={languageData.tooltip} />
            {toolbar &&
              <>
                {['bold', 'italic', 'underline', 'link', 'image', 'video'].map((toolbarValue) => (
                  toolbar[toolbarValue] && <button key={`${id}-${toolbarValue}`} className={`ql-${toolbarValue}`} title={capitalize(toolbarValue)}></button>
                ))}
                {toolbar.list && (
                  <>
                    {toolbar.bullet && <button className="ql-list" value="bullet" title="Bullet"></button>}
                    {toolbar.ordered && <button className="ql-list" value="ordered" title="Ordered"></button>}
                  </>
                )}
                {toolbar.align && (
                  <>
                    <button className="ql-align" value="" title="Left"></button>
                    <button className="ql-align" value="center" title="Center"></button>
                    <button className="ql-align" value="right" title="Right"></button>
                  </>
                )}
                {toolbar.header && (
                  <>
                    {['h1', 'h2', 'h3', 'h4'].map((headingValue) => (
                      toolbar[headingValue] && <button  key={`${id}-${headingValue}`} className="ql-header" value={headingValue[1]} title={capitalize(headingValue)}></button>
                    ))}
                  </>
                )}
              </>
            }
          </div>
          <div className="pull-right d-f align-items-center gap-10 order-3">
            {toolbar?.table && <i className='fa fa-table' onClick={insertNewTable}></i>}
            {notificationTags && <NotificationPlaceholders tags={notificationTags} />}
            {!isQuillEmpty(textValue) && toolbar?.debug && (
              <i onClick={$angularController.showHtml} className="fa fa-code"></i>
            )}
            {requiredData.required && (<ValidLabel tooltip={requiredData.tooltip} valid={!isQuillEmpty(textValue)} />)}
          </div>
        </div>
      <div ref={quillEditorRef}></div>
    </div>
    );
  };

angularize(QuillTextEditor, 'quillTextEditor', angular.module('uasApp'), {
  languageData: '<?',
  requiredData: '<?',
  notificationTags: '<?',
  formats: '<?',
  readOnly: '<?',
  placeholder: '<?',
  toolbar: '<?',
  format: '<?',
  stripHtml: '<?',
  insertTable: '<?',
  value: '<'
});